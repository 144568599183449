export const Platforms = {
	Ios: "iOS",
	Mac: "Max",
	Android: "Android",
	Windows: "Windows",
};
export const DeviceType = {
	Mobile: "Mobile",
	Tablet: "Tablet",
	Laptop: "Laptop",
	Desktop: "Desktop",
};

export const IconSrc = {
	Mobile: "/assets/icons/icon-mobile-iphone.svg",
	Tablet: "/assets/icons/icon-tablet-mac.svg",
	Laptop: "/assets/icons/icon-laptop-mac.svg",
	Desktop: "/assets/icons/icon-desktop-windows.svg",
};

export const Mobiles = [
	{
		name: "iPhone 4",
		type: DeviceType.Mobile,
		icon: {width: 12, src: IconSrc.Mobile},
		css: {width: 320, height: 480}, // 153600
	},
	{
		name: "iPhone 5/5c/5s",
		type: DeviceType.Mobile,
		icon: {width: 14, src: IconSrc.Mobile},
		css: {width: 320, height: 568}, // 181760
	},
	{
		name: "iPhone 6/6s/7/8",
		type: DeviceType.Mobile,
		icon: {width: 16, src: IconSrc.Mobile},
		css: {width: 375, height: 667}, // 250125
	},
	{
		name: "iPhone 11 Pro/X/XS,iPhone 12 Mini",
		type: DeviceType.Mobile,
		icon: {width: 18, src: IconSrc.Mobile},
		css: {width: 375, height: 812}, // 304500
	},
	// {
	// 	name: "iPhone 12, Pro",type:DeviceType.Mobile,
	// 	icon: {width: 18, src: IconSrc.Mobile},
	// 	css: {width: 390, height: 844}, //
	// },
	// {
	// 	name: "iPhone 6+/6s+/7+/8+",type:DeviceType.Mobile,
	// 	icon: {width: 20, src: IconSrc.Mobile},
	// 	css: {width: 414, height: 736}, // 304704
	// },
	{
		name: "iPhone XR/XS Max/11,11 Pro Max",
		type: DeviceType.Mobile,
		icon: {width: 20, src: IconSrc.Mobile},
		css: {width: 414, height: 896}, // 370944
	},
	// {
	// 	name: "Nexus 5, Samsung Galaxy S3/S4/S5/S6/S7, LG G3/G4/G5",
	//  type:DeviceType.Mobile,
	// 	icon: {width: 20, src: IconSrc.Mobile},
	// 	css: {width: 360, height: 640},
	// },
	// {
	// 	name: "Samsung Galaxy S8/S8+/Note 8/S9/S9+/Note 9",
	//  type:DeviceType.Mobile,
	// 	icon: {width: 20, src: IconSrc.Mobile},
	// 	css: {width: 360, height: 740},
	// },
	// {
	// 	name: "Nexus 5X/6p, Pixel/XL/2XL",
	//  type:DeviceType.Mobile,
	// 	icon: {width: 20, src: IconSrc.Mobile},
	// 	css: {width: 412, height: 732},
	// },
	// {
	// 	name: "Pixel 3",
	//  type:DeviceType.Mobile,
	// 	icon: {width: 20, src: IconSrc.Mobile},
	// 	css: {width: 412, height: 824},
	// },
	// {
	// 	name: "Pixel 3 XL",
	//  type:DeviceType.Mobile,
	// 	icon: {width: 20, src: IconSrc.Mobile},
	// 	css: {width: 412, height: 847},
	// },
	// {
	// 	name: "LG G5/One Plus 3/Samsung Galaxy Note 5",
	//  type:DeviceType.Mobile,
	// 	icon: {width: 20, src: IconSrc.Mobile},
	// 	css: {width: 480, height: 853},
	// },
];
export const Tablets = [
	{
		name: "Nexus 7",
		type: DeviceType.Tablet,
		icon: {width: 24, src: IconSrc.Tablet},
		css: {width: 600, height: 960},
	},
	{
		name: "Nexus 9",
		type: DeviceType.Tablet,
		icon: {width: 26, src: IconSrc.Tablet},
		css: {width: 768, height: 1024},
	},
	{
		name: "Samsung Galaxy Tab/2/3 (10 inch)",
		type: DeviceType.Tablet,
		icon: {width: 28, src: IconSrc.Tablet},
		css: {width: 800, height: 1280},
	},
	{
		name: "Microsoft Surface Pro 4/5/6",
		type: DeviceType.Tablet,
		icon: {width: 30, src: IconSrc.Tablet},
		css: {width: 912, height: 1368},
	},
	// {
	// 	name: "iPad mini/2/3/4 iPad 1/2/3/4/Air/Air2/Pro 9.7",
	//  type: DeviceType.Tablet,
	// 	icon: {width: 32, src: IconSrc.Tablet},
	// 	css: {width: 768, height: 1024},
	// },
	{
		name: "iPad Pro",
		type: DeviceType.Tablet,
		icon: {width: 32, src: IconSrc.Tablet},
		css: {width: 1024, height: 1366},
	},
];
export const Laptops = [
	{
		name: "Apple MacBook Pro 13",
		type: DeviceType.Laptop,
		icon: {width: 40, src: IconSrc.Laptop},
		css: {width: 1280, height: 800},
	},
	{
		name: "Apple MacBook Pro 15",
		type: DeviceType.Laptop,
		icon: {width: 42, src: IconSrc.Laptop},
		css: {width: 1440, height: 900},
	},
	{
		name: "Apple MacBook Pro 16",
		type: DeviceType.Laptop,
		icon: {width: 44, src: IconSrc.Laptop},
		css: {width: 1536, height: 960},
	},
	// {
	// 	name: "Dell XPS 13",
	// 	type: DeviceType.Laptop,
	// 	icon: {width: 46, src: IconSrc.Laptop},
	// 	css: {width: 3200, height: 1800},
	// },
	// {
	// 	name: "Dell XPS 15 4K",
	// 	type: DeviceType.Laptop,
	// 	icon: {width: 48, src: IconSrc.Laptop},
	// 	css: {width: 3840, height: 2160},
	// },
];
export const Desktops = [
	{
		name: "24in 1080p Monitor",
		type: DeviceType.Desktop,
		icon: {width: 50, src: IconSrc.Desktop},
		css: {width: 1920, height: 1080},
	},
	{
		name: "27in 1440p Monitor",
		type: DeviceType.Desktop,
		icon: {width: 52, src: IconSrc.Desktop},
		css: {width: 2560, height: 1440},
	},
	{
		name: "32in 4K Monitor",
		type: DeviceType.Desktop,
		icon: {width: 54, src: IconSrc.Desktop},
		css: {width: 3840, height: 2160},
	},

	// {
	// 	name: "iMac 21.5in (Retina 4K)",
	// 	type: DeviceType.Tablet,
	// 	icon: {width: 54, src: IconSrc.Desktop},
	// 	css: {width: 1920, height: 1080},
	// },
	// {
	// 	name: "iMac 27in (Retina 5K)",
	// 	type: DeviceType.Tablet,
	// 	icon: {width: 54, src: IconSrc.Desktop},
	// 	css: {width: 2560, height: 1440},
	// },
];
