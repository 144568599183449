export const isMobileWeb = (win) => isMobile(win) && !isHybrid(win);

export const isIpad = (win) => {
	// iOS 12 and below
	if (testUserAgent(win, /iPad/i)) return true;

	// iOS 13+
	if (testUserAgent(win, /Macintosh/i) && isMobile(win)) return true;

	return false;
};

export const isIphone = (win) => testUserAgent(win, /iPhone/i);

export const isIOS = (win) => testUserAgent(win, /iPhone|iPod/i) || isIpad(win);

export const isAndroid = (win) => testUserAgent(win, /android|sink/i);

export const isAndroidTablet = (win) => isAndroid(win) && !testUserAgent(win, /mobile/i);

export const isPhablet = (win) => {
	const width = win.innerWidth;
	const height = win.innerHeight;
	const smallest = Math.min(width, height);
	const largest = Math.max(width, height);

	return (390 < smallest && smallest < 520)
		&& (620 < largest && largest < 800);
};

export const isTablet = (win) => {
	const width = win.innerWidth;
	const height = win.innerHeight;
	const smallest = Math.min(width, height);
	const largest = Math.max(width, height);

	return (
		isIpad(win) || isAndroidTablet(win) ||
		(
			(460 < smallest && smallest < 820) &&
			(780 < largest && largest < 1400)
		)
	);
};

export const isMobile = (win) => matchMedia(win, '(any-pointer:coarse)');

export const isDesktop = (win) => !isMobile(win);

export const isHybrid = (win) => isCordova(win) || isCapacitorNative(win);

export const isCordova = (win) => !!(win['cordova'] || win['phonegap'] || win['PhoneGap']);

export const isCapacitorNative = (win) => {
	const capacitor = win['Capacitor'];
	return !!(capacitor && capacitor.isNative);
};

export const isElectron = (win) => testUserAgent(win, /electron/i);

export const isPWA = (win) => !!(win.matchMedia('(display-mode: standalone)').matches || (win.navigator).standalone);

export const testUserAgent = (win, regExp) => regExp.test(win.navigator.userAgent);

export const matchMedia = (win, query) => win.matchMedia(query).matches;

// Opera 8.0+
export const isOpera = (win) => {
	return (!!win.opr && !!win.opr.addons)
		|| !!win.opera
		|| win.navigator.userAgent.indexOf(' OPR/') >= 0
};

// Firefox 1.0+
export const isFirefox = (win) => {
	return typeof win.InstallTrigger !== 'undefined'
};

// Safari 3.0+ "[object HTMLElementConstructor]"
export const isSafari = (win) => {
	return /constructor/i.test(win.HTMLElement) || (
		p => p.toString() === "[object SafariRemoteNotification]"
	)(!win['safari'] || (typeof win.safari !== 'undefined' && win['safari'].pushNotification))
};

// Internet Explorer 6-11
export const isIE = (win) => {
	/*@cc_on!@*/
	return false || !!win.document.documentMode
};

// Edge 20+
export const isEdge = (win) => {
	return !isIE(win) && !!win.StyleMedia
};

// Chrome 1 - 79
export const isChrome = (win) => {
	return !!win.chrome && (!!win.chrome.webstore || !!win.chrome.runtime)
};

// Edge (based on chromium) detection
export const isEdgeChromium = (win) => {
	return isChrome(win) && (win.navigator.userAgent.indexOf("Edg") != -1)
};

// Blink engine detection
export const isBlink = (win) => {
	return (isChrome(win) || isOpera(win)) && !!win.CSS
};
