<script>
	import {onMount} from "svelte";
	import {DeviceType} from "./devices";
	import {isDesktop} from "./utils/platform";

	export let src;
	export let selectedDevice;

	$: width = selectedDevice && selectedDevice.css.width;
	$: height = selectedDevice && selectedDevice.css.height;

	let frameContainerEl;
	let frameEl;
	let frameScale = 1;
	const frameBorderWidth = 8;
	const xOffset = frameBorderWidth * 2 + 8;
	const yOffset = frameBorderWidth * 2 + 8;

	const scrollbarPadding = 15;
	// update shouldPadScrollbar when selectedDevice change
	$: shouldPadScrollbar = getPadScrollbar(selectedDevice);
	$: console.log(`shouldPadScrollbar<${typeof shouldPadScrollbar}> = `, shouldPadScrollbar);

	onMount(() => {
		setFrameScale();
	})

	// when width or height change update iframe scale
	$: if (width || height) {
		setFrameScale();
	}

	function setFrameScale() {
		if (!frameContainerEl || !frameContainerEl.getBoundingClientRect) {
			return
		}
		const frameWidth = frameContainerEl.getBoundingClientRect().width
		const frameHeight = frameContainerEl.getBoundingClientRect().height

		let xScale = 1;
		let yScale = 1;

		if (width > frameWidth - xOffset) {
			xScale = (frameWidth - xOffset) / width;
		}
		if (height > frameHeight - yOffset) {
			yScale = (frameHeight - yOffset) / height;
		}

		frameScale = Math.min(xScale, yScale);

		// console.log(`fw: ${frameWidth}  fh: ${frameHeight}  xScale: ${xScale}  yScale: ${yScale} `);
	}

	function getURL(src) {
		let url = src.trim();
		if (!url.startsWith(`http://`) && !url.startsWith(`https://`)) {
			url = `https://${url}`
		}
		url = new URL(url);
		return url.toString();
	}

	function getPadScrollbar(selectedDevice) {
		if (!selectedDevice || !selectedDevice.type) {
			return false
		}
		return (selectedDevice.type === DeviceType.Mobile || selectedDevice.type === DeviceType.Tablet)
			&& isDesktop(window);
	}
</script>


<svelte:window on:resize={setFrameScale}/>

<section>
	<div class="info-container">
		<p>
			<span>{selectedDevice && selectedDevice.type}</span> <b>|</b>
			<span>{width}px x {height}px</span> <b>|</b>
			<span>(scale {frameScale.toFixed(2)})</span>
		</p>
	</div>

	<div bind:this={frameContainerEl} class="frame-container">
		<div class="frame-wrapper"
				 style="width:{width*frameScale}px; height:{height*frameScale}px;  border: {frameBorderWidth}px solid #000000">
			<iframe
					bind:this={frameEl}
					src={getURL(src)}
					title="iframe"
					width={shouldPadScrollbar ? (width+scrollbarPadding) : width}
					{height}
					style="transform:  scale({frameScale});">
			</iframe>
		</div>
	</div>
</section>


<style>
    section {
        display: flex;
        flex-flow: column nowrap;

        width: 100%;
        height: 100%;

        overflow: hidden;
    }

    .info-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: .5em;

        flex: 0 1 auto;
    }
    .info-container > p {
        display: inline-block;
        width: fit-content;

        margin: 0;
        padding: 0.5em 1em;

        border-radius: 0 0 8px 8px;

        text-align: center;
        color: darkgrey;
        background: white;
    }
    .info-container > p > span {
        color: #515151;
    }

    .frame-container {
        --transition-duration: .3s;

        display: flex;
        flex-flow: column nowrap;
        justify-content: start;
        align-items: center;

        flex: 1 1 auto;

        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .frame-wrapper {
        width: fit-content;
        height: fit-content;
        overflow: hidden;
        border-radius: 16px;
        box-sizing: content-box;
        background-color: #ffffff;
        transition: all var(--transition-duration) ease;

        z-index: 10;
    }

    iframe {
        display: block;
        border: none;
        transform-origin: 0 0;
        transition: all var(--transition-duration) ease;

        z-index: 1;
    }
</style>

