<script>
	import Header from "./Header.svelte";
	import DeviceSlider from "./DeviceSlider.svelte";
	import Iframe from "./Iframe.svelte";

	let selectedDevice;
	let src = "wikipedia.org";

	function onSearch(data) {
		const searchTerm = data.detail.searchTerm.trim();

		if (searchTerm === src) {
			src = ''
			setTimeout(() => {
				src = searchTerm
			}, 0)
			return;
		}

		src = searchTerm
	}

	function onDeviceSelect(event) {
		selectedDevice = event.detail.device;
	}
</script>

<main>
	<Header url={src} on:search={onSearch}/>
	<DeviceSlider on:deviceSelect={onDeviceSelect}/>
	<Iframe {src} {selectedDevice}/>
</main>


<style>
    main {
        display: flex;
        flex-flow: column;
        height: 100%;
        width: 100%
    }
</style>
