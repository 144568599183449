<script>
	import {createEventDispatcher} from "svelte";
	import Redo from 'svelte-icons/fa/FaRedo.svelte';

	export let url;

	const dispatch = createEventDispatcher();

	let searchInput;
	let windowInnerWidth;

	function search() {
		searchInput.blur();
		dispatch("search", {searchTerm: url});
	}
</script>


<svelte:window bind:innerWidth={windowInnerWidth}/>

<header style="background: black; color: white;">
	<div class="logo">
		<img src="favicon.svg" alt="" width="28px">
	</div>
	<form class="form" on:submit|preventDefault={search}>
		<input
				class="form__input"
				type="url" name="url" id="url"
				placeholder="www.google.com"
				autocomplete
				aria-autocomplete="inline"
				bind:this={searchInput}
				bind:value={url}/>
		<button class="form__submit" on:click|preventDefault={search}>Load</button>
		<span class="form__reload" on:click|preventDefault={search}>
			<Redo/>
			</span>
	</form>
</header>


<style>
    header {
        display: flex;
        justify-content: center;
        align-items: center;

        flex: 0 1 auto;

        width: 100%;
        min-width: 360px;
        height: 56px;
        padding: 8px 16px;
        box-shadow: 0px 0px 3px 1px #666;
    }

    .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        font-size: 2.5rem;
        font-family: monospace;
        margin: 0 16px 0 0;

        transition: width 200ms;
        -webkit-transition: width 200ms;
    }

    .form {
        display: inline-flex;
        align-items: center;
    }


    .form__input {
        display: inline-flex;
        background-color: #FFFFFF;
        border: none;
        border-radius: 4px 0 0 4px;
        padding-left: 16px;
        margin: 0;
        height: 36px;
        width: 14ch;
        max-width: 600px;
    }
    .form__input::placeholder {
        color: #585858;
    }
    .form__input:focus,
    .form__input:focus-visible {
        outline: none;
        /*border: 1px solid #333333;*/
    }

    @media (min-width: 320px) {
        /* Mobile-small-up */
        .form__input {
            width: 14ch;
        }
    }

    @media (min-width: 375px) {
        /* Mobile-medium-up */
        .form__input {
            width: 18ch;
        }
    }

    @media (min-width: 600px) {
        /* Tablet-portrait-up */
        .form__input {
            width: 26ch;
        }
    }

    @media (min-width: 900px) {
        /* Tablet-landscape-up */
        .form__input {
            width: 36ch;
        }
    }

    .form__submit {
        color: black;
        font-weight: bold;
        border: none;
        height: 36px;
        margin: 0;
        padding: 10px;
        background-color: #f1f1f1;
        border-radius: 0 4px 4px 0;
    }

    .form__reload {
        color: #FFF;
        width: 22px;
        height: 22px;
        margin: 0 16px;
        border-radius: 50%;
        transition: all .3s ease;
    }

    .form__reload:hover {
        transform: rotate(45deg) scale(1.1);
    }
</style>
