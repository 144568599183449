<script>
	import {createEventDispatcher, onMount} from "svelte";
	import {Desktops, Laptops, Mobiles, Tablets} from "./devices";

	const dispatchEvent = createEventDispatcher();
	const DeviceGroups = [Mobiles, Tablets, Laptops, Desktops];

	let wrapperEl;
	let selectedDevice = {};
	let focusedDevice = {};


	onMount(() => {
		selectDevice(Mobiles[2])();
	})

	function selectDevice(device) {
		return () => {
			selectedDevice = device;
			dispatchEvent("deviceSelect", {
				device: device
			});
		};
	}

	function focusDevice(device) {
		return () => {
			if (focusDevice === device) {
				return
			}
			focusedDevice = device;
			dispatchEvent("deviceSelect", {
				device: device
			});
		};
	}

	function unFocusDevice() {
		focusedDevice = {};
		dispatchEvent("deviceSelect", {
			device: selectedDevice
		});
	}

	function handleWindowResize(event) {
	}
</script>

<svelte:window on:resize={handleWindowResize}/>

<section bind:this={wrapperEl} on:mouseout={unFocusDevice}>
	<div class="group">
		{#each DeviceGroups as deviceGroup}
			<div class="device-group">
				{#each deviceGroup as device}
					<div
							class="device"
							class:focused={device.name === focusedDevice.name}
							class:selected={device.name === selectedDevice.name}
							on:pointermove={focusDevice(device)}
							on:click={selectDevice(device)}>
						<img
								style="width:{device.icon.width}px;"
								src={device.icon.src}
								alt="mobile"/>
					</div>
				{/each}
			</div>
		{/each}
	</div>
</section>


<style>
    section {
        display: flex;
        flex-flow: row nowrap;

        flex: 0 0 auto;

        height: fit-content;

        overflow-y: hidden;
        white-space: nowrap;
    }

    .group {
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 0.5em 1em;
        margin: auto;
    }

    .device-group {
        display: inline-flex;
        align-items: center;
        margin: 0;
    }

    .device {
        width: 60px;
        height: 60px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
    }

    .device.focused {
        background-color: #fbbb1d94;
        width: 60px;
    }

    .device.selected {
        background-color: #fbbb1d;
    }

    .device > img {
        transition: transform .2s ease-in-out;
        transform-origin: 50% 0%;
    }
</style>
